
#interstitial {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

#interstitial.hidden {
  display: none;
}

.interstitial-bg {
  height: 100%;
  width: 100%;
  background: rgba(61,69,84,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.interstitial-box {
  max-width: 80%;
  min-width: 50%;
  /* height: 300px; */
  background: #fff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  /* padding: 3rem; */
}

.interstitial-content {
  padding: 3rem;
  font-weight: 400;
  position:relative;
}

.interstitial-content .title {
  font-weight: 600;
  color: var(--main-color)
}

.interstitial-content .icon {
  width: 30px;
  flex: 0 0 7%;
}

.interstitial-content .icon svg {
  fill: var(--main-color);
  font-size: 3rem;
}

.interstitial-content li {
  display: flex;
  align-items: flex-start;
  margin-left: 2rem;
}

.interstitial-content li p {
  margin-bottom: 0;
}

.interstitial-content b {
  font-weight: 600;
  color: var(--purple)
}

.interstitial-buttons {
  display: inline-block;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.interstitial-buttons button {
  width: 50%;
  display: inline-block;
  padding: 2.5rem 0;
  font-size: 2rem;
  line-height: 0;
  border-radius: 0;
  background: var(--light-gray);
  border-color: var(--light-gray);
  color: #aaa;
  margin-bottom: 0;
}

.interstitial-buttons button:hover,
.interstitial-buttons button:active {
  background: #e7e7e7;
}

.interstitial-buttons button.green{
  background: var(--main-color);
  border-color: var(--main-color);
  color: #fff;
}

.interstitial-buttons button.green:hover,
.interstitial-buttons button.green:active {
  background: #4cb189;
  border-color: #4cb189;
}
